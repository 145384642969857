import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state() {
    return {
      page_info: {
        name: "",
        page_option: {},
      },
    }
  },
  mutations: {
    set_token: function (state, token) {
      state.token = token;
    },
    set_page: function (state, page_info) {
      state.page_info = page_info;
    },
    get_page: function (state) {
      return state.page_info;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState()
  ]
})
